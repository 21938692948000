import clsx from "clsx";
import { routes } from "../../routes";
import logo from "../../imgs/logo.png";

export default function DesktopNav() {
  return (
    <div className={parentContainer}>
      <a href="/">
        <img
          src={logo}
          alt="handwriting that says Violet Danse"
          className="w-[200px] h-auto mb-10"
        />
      </a>
      {routes.map(({ link, title }) => {
        const location = window.location.pathname;
        return (
          <a
            key={title}
            className={"mb-8 flex" + (location === link && " font-bold")}
            href={link}
          >
            {title}
          </a>
        );
      })}
    </div>
  );
}

const parentContainer = clsx("hidden lg:flex flex-col w-[300px] justify-center");
