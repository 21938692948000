import rehearsal from "../imgs/rehearsal.jpg";
export default function Bio() {
  return (
    <div className="flex flex-col lg:flex-row justify-center overflow-no-scroll">
      <div className="flex lg:mr-20 flex-col lg:mt-10 relative">
        <div className="mb-4">
          <img
            src={rehearsal}
            className="w-full mb-[2px] h-auto"
            alt="violet standing with their arms on their hips looking at the distance in a dance studio with a wall clock in the background"
          />
          <div className="lg:text-right text-center text-[10px] text-black/40">
            Stage 6 Rehearsal, 2015, Photo by Unknown
          </div>
        </div>
        <div className="text-xs text-justify p-4 lg:p-0">
          {/* <div className="font-bold text-4xl">Violet Moon</div> */}
          <span className="font-bold">Violet Moon</span> (they/them) is a
          polymathic artist who has performed across the United States as a
          selected dance artist, soloist, and touring company member. They are a
          City of Houston and Houston Arts Alliance Let Creativity Happen grant
          recipient and have had their paintings featured across the city. They
          write, sing, make music, craft, and value the process of Natalie
          Rogers’ model of Creative Connection.
          <div className="mt-4">
            Recently, Violet has been exploring movement and artistry beyond
            conditioned Western structures. Their work is driven by a hope of
            dismantling indoctrination and the hierarchy of privilege to honor
            cultural and ancestral movement patterns. They are passionate about
            creating meaningful experiences that translate stories of pain,
            pleasure, and loss into something honest, resonant, and human.
          </div>
          <div className="mt-4">
            Their accomplishments include a Masters in Clinical Mental Health
            Counseling, somatic and expressive arts therapy training, guest
            dance artist contracts, international residencies, and a loyalty to
            their creative practice. Rooted in a deep reverence for the
            ever-evolving Self, Violet remains devoted to life’s dance.
          </div>
        </div>
      </div>
      {/* <div className="mt-10 font-bold text-2xl mb-2">Background</div> */}
      <div className="my-4 flex flex-col items-center lg:items-start flex-wrap text-justify text-xs overflow-scoll">
        {accomplishments.map((item) => (
          <div className="w-[180px] border-b-[1px] py-4">{item}</div>
        ))}
      </div>
    </div>
  );
}

// Limits not as blockers, but possibilities to redraw and honor the everchanging Self.

const accomplishments = [
  "City of Houston and Houston Arts Alliance 'Let Creativity Happen' Grant Recipient",
  "Dance Source Houston Member",
  "Thematic Lab with Stanley Ollivier on Energy & Care at P.A.R.T.S Summer Residency",
  "Training Acceptance into Somatic Experiencing® International",
  "Certificate in Individual Foundations of Expressive Arts Therapy 1 & 2 from Center For Creative Arts Therapy",
  "Therapeutic Dance Movement Educator at The Monarch Institute",
  "ARCOS Dance Company Guest Artist Contract",
  "ARCOS Dance Summer Residency 2016",
  "Hubbard Street Dance Chicago Summer Residency 2016",
  "Acceptance into Hubbard Street Dance Chicago Choreographic Residency in Los Angeles, 2016",
  "Founding Member of LÉON Contemporary Dance Company",
  "Hubbard Street Dance Chicago Winter Residency 2015",
  "Hubbard Street Dance Chicago Summer Residency 2015",
  "Revolve Dance Company Apprentice",
];

// is a creator
// and polymathic artist. Over the last decade, they have toured and
// performed select works as an invited artist, soloist, and company
// member across the United States. Violet writes, makes music, and has
// also had their visual artwork featured across Houston. In addition to
// recently choreographing several original works, Violet frequently
// studies around the world. They are often collaborating cross-medium
// and across venue while acknowledging/researching the privilege and
// requesting/respecting the consent of inquiry into unknown methods and
// experiences.
// In their creative practice, Violet is now exploring what movement
// means— defining artistry outside of and along the edges of the
// confines of standardized Western beauty. As a working mental health
// professional, Violet also likes to challenge the patterns and
// assumptions of engrained social behavior and beliefs of care. There
// are so many ways to relate to self and consequently others and life at
// large. This journey of dismantling indoctrination to welcome the
// natural rhythms of global and ancestral movement patterns to inform
// peformance as a metaphor and connector drives much of their work.
// Violet is passionate, focused, and hopeful about making processes and
// products that allow who they are to fill spaces authentically with the
// desire to translate pain, pleasure, and humanity into something
// impactful, resonsant, and meaningful for others.
