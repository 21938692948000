import * as React from "react";
import * as ReactDOM from "react-dom/client";
import AppBase from "./pages/Base";

import reportWebVitals from "./reportWebVitals";
import "./index.css";

reportWebVitals();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AppBase />
  </React.StrictMode>
);
