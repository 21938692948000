export const anthology = [
  {
    title: "The Thread Between Us: Cut",
    event: "Barnstorm VIII - Program C",
    reel: "https://www.youtube.com/embed/ZvH4eUSqjjU",
    eventLink: "https://matchouston.org/events/2024/barnstorm-dance-fest-2024",
    features: [],
    date: "May 28 - June 1, 2024",
    time: "19:30",
    choreographer: "Violet Moon",
    performer: "Alisa Mittin, Violet Moon",
    style: "Dance Theatre",
    location: "Midtown Arts & Theater Center Houston), Matchbox 2",
    music: "Computer Love by Balanescu Quartet, and To Speak Of Solitude by Brambles (in clip; trimmed)",
    production: "Dance Source Houston",
    notes: "",
    footageCredit: "",
  },
  {
    title: "Ribbons & Ruin",
    event: "On Film",
    reel: "https://www.youtube.com/embed/PNCXMyq7Dyg",
    eventLink: "",
    features: [],
    date: "April 8, 2024",
    time: "11:00",
    choreographer: "Amanda Monteith",
    performer: "Amanda Monteith, Kaylee Cates, Makenna Grace, Violet Moon",
    style: "Performance Art",
    location: "Casa Luz, Houston, Texas",
    music: "Slava by Abel Korzeniowski",
    production: "Amanda Monteith, filmed by Vianney Rodriguez",
    notes: "",
    footageCredit: "",
  },
  {
    title: "Wanting The Sun",
    event: "On Film",
    reel: "https://www.youtube.com/embed/rZx8Fm7JKM0",
    eventLink: "",
    features: [],
    date: "February 4, 2024",
    time: "16:00",
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Performance Art",
    location: "Galveston Beach, Texas",
    music: "Ambient-style emotional piano by MoppySound",
    production: "CapCut",
    notes: "",
    footageCredit: "",
  },
  {
    title: "What Could I Have Done",
    event: "Vincent",
    reel: "https://www.youtube.com/embed/evmQianzuF0",
    date: "December 24, 2023",
    time: "19:30",
    eventLink: "https://matchouston.org/events/2023/vincent",
    features: [""],
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Contemporary Ballet",
    location: "Midtown Arts & Theater Center Houston), Matchbox 4",
    music: "Savior, produced for Kevo Arts",
    production: "Kevo Arts",
    footageCredit: "Stephanie Bobak",
    notes: "",
  },
  {
    title: "Holy Place",
    event: "On Film",
    reel: "https://www.youtube.com/embed/Q9OIlq8boW0",
    eventLink: "",
    features: [],
    date: "November 5, 2023",
    time: "14:00",
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Performance Art",
    location: "Memorial Park Houston",
    music: "Elegy by Happy Spread Music",
    production: "CapCut",
    notes: "",
    footageCredit: "",
  },
  {
    title: "The Risk",
    event: "Mind The Gap XXV",
    reel: "https://www.youtube.com/embed/PuRBcIHv5PY",
    eventLink: "https://matchouston.org/events/2023/mind-gap-xxv",
    date: "August 1, 2023",
    time: "19:30",
    features: [
      "https://thedancedish.org/dance-source-houston-presents-mind-the-gap-xxv/",
      "https://thedancedish.org/mind-the-gap-turns-25-with-a-new-lineup-of-dancemakers/",
    ],
    choreographer: "Violet Moon",
    performer: "Violet Moon",
    style: "Contemporary Performance Art",
    location: "Midtown Arts & Theater Center Houston), Matchbox 2",
    music: "Happy Loner by Marina, and Goodbye by Marina",
    production: "Dance Source Houston",
    notes: "",
    footageCredit: "Dance Source Houston",
  },

  {
    title: "In Communication With Vibration",
    event: "A Gift From The Bower",
    date: "Apil 22, 2023",
    time: "17:00",
    reel: "https://www.youtube.com/embed/V9gGIYu2km4",
    eventLink:
      "https://diverseworks.org/in-the-works/exhibtion-performance/a-gift-from-the-bower/",
    features: [
      "https://giftfromthebower.org/bower-5-charmaine-locke/, https://www.lockesurlscenter.com/past-events, https://diverseworks.org/wp-content/uploads/2023/04/A-Gift-From-The-Bower-Performance-Schedule.pdf, https://diverseworks.org/past-works/archive/a-gift-from-the-bower/",
    ],
    choreographer: "Improvisational",
    performer: "Aaron Bielish, Lynn Lane, Violet Moon",
    style: "Performance Art",
    location: "Splendora Gardens, Bower 5",
    music: "Transitory Sound and Movement Collective",
    production: "DiverseWorks & Locke Surls Center for Art and Nature",
    footageCredit: "John Danielson",
    notes:
      "Installation by Charmaine Locke title Beehive, 2023 using steel, hydrostone and paint.",
  },
  {
    title: "Giving While Needing",
    event: "Mind The Gap XXIII",
    reel: "https://www.youtube.com/embed/k5n5uUI2jdc",
    eventLink: "https://matchouston.org/events/2023/mind-gap",
    features: [
      "https://thedancedish.org/choreographers-mind-the-gap-for-the-first-time-in-2023/",
      "https://thedancedish.org/dance-source-houston-presents-mind-the-gap-xxiii/",
    ],
    date: "February 28, 2023",
    time: "19:30",
    choreographer: "Violet Moon",
    performers: "Violet Moon",
    style: "Contemporary Dance",
    location: "Midtown Arts & Theater Center Houston), Matchbox 2",
    music: "Liability by Lorde, and Liability (reprise) by Lorde",
    production: "Dance Source Houston",
    notes: "",
    footageCredit: "Dance Source Houston",
  },
  {
    title: "Where is The Light?",
    event: "The Shout",
    reel: "https://www.youtube.com/embed/BVmpb9eLEVA",
    eventLink: "https://www.facebook.com/events/1623004541260881/?ref=newsfeed",
    features: [
      "https://www.facebook.com/media/set/?set=a.341775849361118&type=3",
    ],
    date: "January 29, 2015",
    time: "19:00",
    choreographer: "Improvisational",
    performer: "Tam Fitzgerald, Violet Moon",
    style: "Improvisation",
    location: "Barnevelder Movement/Arts Complex",
    music: "Spoken Word by Tam Fitzgerald",
    production: "The Shout",
    footageCredit: "",
    notes: "Photo credit: Mark Francis",
  },
  {
    title: "What is Freedom?",
    event: "The Shout",
    reel: "https://www.youtube.com/embed/DLx9cI2gNUU",
    eventLink:
      "https://www.eventbrite.com/e/the-shout-tickets-13553411619?aff=zvents",
    features: [
      "https://www.facebook.com/media/set/?set=a.306167672921936&type=3",
    ],
    date: "October 23, 2014",
    time: "19:00",
    choreographer: "Improvisational",
    performer: "Brianna Hall, Violet Moon",
    style: "Improvisation",
    location: "Barnevelder Movement/Arts Complex",
    music: "Spoken Word by Brianna Hall",
    production: "The Shout",
    footageCredit: "",
    notes: "",
  },
];

export const postEvents = [
  {
    event: "Barnstorm VIII, Program C",
    eventLink: "",
    displayDate: "May 28-June 1, 2024",
    formatDate: "June 1, 2024",
    performer: "Violet Moon, Alisa Mittin",
    style: "Contemporary Dance",
    location: "Midtown Arts & Theater Center Houston",
    production: "Dance Source Houston",
  },
  {
    event: "Guardians",
    eventLink: "",
    displayDate: "August 6-September 4, 2023",
    formatDate: "September 4, 2023",
    performer: "Violet Moon",
    style: "Abstract Portrait Paintings",
    location: "Verbena Coffee House",
    production: "Verbena",
  },
  {
    event: "Mind The Gap XXV",
    eventLink: "https://matchouston.org/events/2023/mind-gap-xxv",
    displayDate: "August 1, 2023",
    formatDate: "August 1, 2023",
    performer: "Violet Moon",
    style: "Performance Art",
    location: "Midtown Arts & Theater Center Houston",
    production: "Dance Source Houston",
  },
  {
    event: "A Gift From The Bower",
    eventLink:
      "https://diverseworks.org/in-the-works/exhibtion-performance/a-gift-from-the-bower/",
    displayDate: "April 22, 2023",
    formatDate: "April 22, 2023",
    performer: "Transitory Sound And Movement Collective",
    style: "Performance Art",
    location:
      "Locke Surls Center for Art and Nature, Bower 5, Splendora, Texas",
    production: "DiverseWorks & Locke Surls Center for Art and Nature",
  },
  {
    event: "Mind The Gap XXIII",
    eventLink: "https://matchouston.org/events/2023/mind-gap",
    displayDate: "February 28, 2023",
    formatDate: "February 28, 2023",
    performer: "Violet Moon",
    style: "Contemporary Dance",
    location: "Midtown Arts & Theater Center Houston",
    production: "Dance Source Houston",
  },
  {
    event: "This is Me",
    eventLink: "",
    displayDate: "August 3-September 2, 2022",
    formatDate: "September 2, 2022",
    performer: "Violet Moon",
    style: "Abstract Paintings",
    location: "Verbena Coffee House",
    production: "Verbena",
  },
];
export const preEvents = [
  {
    event: "Choreographers X6",
    eventLink:
      "https://www.chron.com/neighborhood/memorial/events/article/JCC-presents-Houston-Choreographers-X6-dance-10884718.php",
    displayDate: "February 12, 2017",
    formatDate: "February 12, 2017",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "Kaplan Theatre",
    production: "Evelyn Rubenstein Jewish Community Center of Houston",
  },
  {
    event: "Generation Dance Festival",
    eventLink:
      "https://matchouston.org/events/2016/generation-dance-festival-houston",
    displayDate: "May 14-15, 2016",
    formatDate: "May 15, 2016",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "Midtown Arts & Theater Center Houston",
    production: "LÉON Dance Arts",
  },
  {
    event: "Stage 6 Dance",
    eventLink: "https://matchouston.org/events/2015/stage-6-dance",
    displayDate: "December 13, 2015",
    formatDate: "December 13, 2015",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "Midtown Arts & Theater Center Houston",
    production: "LÉON Contemporary Dance Company",
  },
  {
    event: "Barnstorm I, Program 1",
    eventLink:
      "https://thedancedish.org/dance-source-houston-announces-lineup-for-the-barnstorm-dance-fest/",

    displayDate: "May 28-30, 2015",
    formatDate: "May 30, 2015",
    performer: "LÉON Contemporary Dance Company",
    style: "Contemporary Dance",
    location: "Barnevelder Movement/Arts Complex",
    production: "Dance Source Houston",
  },
  {
    event: "CounterCurrent Festival",
    eventLink: "https://www.houstonpublicmedia.org/articles/arts-culture/2015/04/15/59387/countercurrent-features-a-unique-tribute-to-powerful-women-2/",
    displayDate: "April 15, 2015",
    formatDate: "April 15, 2015",
    performer:
      "Celestina Billington, Bonnie Christiansen, Anastasiya Kopteva, Michiko McMahon, Violet Moon",
    style: "Dance Theatre",
    location: "Wilhelmina Grove",
    production:
      "Cynthia Woods Mitchell Center for the Arts, Sara Pauley, Omar Abi Azar, and Maya Zbib from Zoukak Theater in Lebanon",
  },
  {
    event: "The Shout: Where is The Light?",
    eventLink: "https://www.facebook.com/events/1623004541260881/?ref=newsfeed",
    displayDate: "January 29, 2015",
    formatDate: "January 29, 2015",
    performer: "Tam Fitzgerald, Violet Moon",
    style: "Improvisation",
    location: "Barnevelder Movement/Arts Complex",
    production: "The Shout Houston TX",
  },
  {
    event: "X",
    eventLink:
      "https://twitter.com/DanceSource/status/532926263175180289?ref_src=twsrc%5Etfw",
    displayDate: "Nov 14-15, 2014",
    formatDate: "Nov 15, 2014",
    performer: "Revolve Dance Company",
    style: "Contemporary Dance",
    location: "Barnevelder Movement/Arts Complex",
    production: "Revolve Dance Company",
  },
  {
    event: "The Shout: What is Freedom?",
    eventLink:
      "https://www.eventbrite.com/e/the-shout-tickets-13553411619?aff=zvents",
    displayDate: "October 23, 2014",
    formatDate: "October 23, 2014",
    performer: "Brianna Hall, Violet Moon",
    style: "Improvisation",
    location: "Barnevelder Movement/Arts Complex",
    production: "The Shout Houston TX",
  },
  {
    event: "Dance Gallery Festival",
    eventLink:
      "https://danceinforma.us/articles/texass-dance-gallery-festival-enters-5th-year/",
    displayDate: "October 17, 2014",
    formatDate: "October 17, 2014",
    performer: "Revolve Dance Company",
    style: "Contemporary Dance",
    location:
      "James and Nancy Gaertner Performing Arts Center & Dance Theater",
    production: "Sam Houston State University",
  },
];
