import clsx from "clsx";
import mtg from "../imgs/home-bg.jpg";

export default function Main() {
  return (
    <div className={parentContainer}>
      <div className="h-[calc(100vh-180px)]">
        <img src={mtg} className={img} alt={altText} />
      </div>
      <div className={caption}>
        Mind The Gap XXIII, Giving While Needing, Photo by Lynn Lane Photography
      </div>
    </div>
  );
}

const altText =
  "violet dancing in black dress with blackout curtain behind them";
const parentContainer = clsx("lg:h-screen flex flex-col justify-center");
const img = clsx(
  "mb-[2px] self-center object-cover object-center h-full w-full"
);
const caption = clsx(
  "text-center lg:text-right text-[10px] lg:text-black/40 text-white/40 bg-[#010100] lg:bg-white -mt-[1px] pb-5"
);
