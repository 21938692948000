import clsx from "clsx";
import { RouterProvider } from "react-router-dom";
import { router } from "../routes";
import { DesktopNav, MobileNav } from "../components";

export default function AppBase() {
  return (
    <>
      <MobileNav />
      <div className={parentContainer}>
        <DesktopNav />
        <div className={routesContainer}>
          <RouterProvider router={router} />
        </div>
      </div>
    </>
  );
}

const parentContainer = clsx(
  "lg:h-screen flex justify-content items-center relative lg:p-20 w-full"
);
const routesContainer = clsx(
  "flex flex-col lg:w-2/3 lg:h-screen overflow-y-scroll"
);
