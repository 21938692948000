import clsx from "clsx";
import { useState } from "react";
import { routes } from "../../routes";
import logo from "../../imgs/logo.png";

export default function MobileNav() {
  const [dropdownOpen, setDropdown] = useState(false);
  const location = window.location.pathname;
  const homepage = location === "/";
  return (
    <div className={parentContainer}>
      <div
        onClick={() => setDropdown(!dropdownOpen)}
        className={dropdownBtn + (homepage && " bg-[#010100]")}
      >
        <img
          src={logo}
          alt="handwriting that says Violet Danse"
          className={"w-[200px] h-auto " + (homepage && " invert")}
        />
      </div>
      {dropdownOpen ? (
        <div className={dropdownContainer}>
          <a className={"mb-12 " + (homepage && selectedStyle)} href={"/"}>
            Home
          </a>
          {routes.map(({ link, title }) => {
            const selected = location === link;
            return (
              <a
                key={title}
                className={"mb-12 " + (selected && selectedStyle)}
                href={link}
              >
                {title}
              </a>
            );
          })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const parentContainer = clsx("lg:hidden w-screen justify-center flex flex-col");
const dropdownBtn = clsx(
  "flex items-center justify-center w-full py-4 cursor-pointer -mb-[1px]"
);
const dropdownContainer = clsx(
  "absolute h-[calc(100vh-150px)] bg-[#010100]/80 mt-[120px] flex flex-col justify-center items-center text-lg w-full text-white bottom-0 text-black z-10"
);
const selectedStyle = clsx(
  "  border-2 border-white italic w-1/2 bg-white text-black text-center "
);
